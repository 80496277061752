import './Uthred.scss';

import React from 'react';
import { injectIntl } from 'react-intl';
import Buttons from '../Buttons/Buttons';
import tt from '../../helpers/translation';

class Uthred extends React.Component {
  constructor() {
    super();
    this.state = {
      isModal: true,
    };
  }

  handleChange = () => {
    this.setState({
      isModal: false,
    });
  };

  render() {
    const { data } = this.props;
    const { isModal } = this.state;
    return (
      <div className="uthred-container">
        {isModal && (
          <div className="modal is-active">
            <div className="modal-background" tabIndex="0" />
            <div className="modal-content">
              <div className="modal-title" dangerouslySetInnerHTML={{ __html: data.title }} />
              <div className="modal-body" dangerouslySetInnerHTML={{ __html: data.body }} />
              <Buttons
                onClick={this.handleChange}
                size="alone"
                color="orange"
                text={data.ctaText}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default injectIntl(Uthred);
