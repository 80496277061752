import React from 'react';
import { graphql } from 'gatsby';
import Heimdall from '../src/components/Heimdall/Heimdall';
import H2 from '../src/components/Titles/TitleH2/TitleH2';
import Hod from '../src/components/Hod/Hod';
import Layout from '../src/components/Layout/Layout';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import { Freya } from '../src/components/Freya/Freya';
import Uthred from '../src/components/Uthred/Uthred';
import SEO from '../src/helpers/seo';

import './material_grafico.scss';

export const GatsbyQuery = ({ data, pathContext: { locale } }) => graphql`
  query materialQuery($locale: String!) {
    allMaterialGraficoSeoBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allMaterialGraficoHeaderImage(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allMaterialGraficoModal(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          body
          ctaText
        }
      }
    }
    allMaterialGraficoBreadcrumb(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          name
        }
      }
    }
    allMaterialGraficoTitleBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          subtitle
        }
      }
    }
    allMaterialGraficoFreyaBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          number_fotos
          cta_download
          cta
          ctaText
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
        }
      }
    }
    allMaterialGraficoRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            color_css_class
            og_description
            og_title
            twitter_description
            twitter_title
            created_at
            updated_at
            rich_markup_type
          }
        }
      }
    }
  }
`;

class MaterialGrafico extends React.Component {
  state = {};

  PrepareFreya = (src) => ({
    title: this.props.data.allMaterialGraficoTitleBlock.edges[0].node.subtitle,
    isSlider: true,
    cards: src.edges.map((e) => ({
      downloadType: true,
      image: e.node.localImage.childImageSharp.fluid,
      altImage: e.node.image_alt,
      imgsize: 'promoted',
      title: e.node.title,
      description: e.node.number_fotos,
      download: e.node.cta_download,
      buttons: {
        size: 'alone',
        color: 'white',
        ctaText: e.node.ctaText,
        cta: e.node.cta,
      },
    })),
  });

  render() {
    const heimdallData = {
      name: this.props.data.allMaterialGraficoTitleBlock.edges[0].node.title,
      image: {
        url: this.props.data.allMaterialGraficoHeaderImage.edges[0].node.localImage.childImageSharp
          .fluid,
        alt: this.props.data.allMaterialGraficoHeaderImage.edges[0].node.alt,
      },
    };

    return (
      <Layout
        layoutFromOld
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
      >
        <SEO
          title={this.props.data.allMaterialGraficoSeoBlock.edges[0].node._0.title}
          description={this.props.data.allMaterialGraficoSeoBlock.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="general-index">
            <div className="material-grafico-container">
              <Uthred data={this.props.data.allMaterialGraficoModal.edges[0].node} />
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                title={this.props.data.allMaterialGraficoBreadcrumb.edges[0].node.name}
              />
              <Hod
                data={this.props.data.allMaterialGraficoRichData.edges[0].node.rich_markup}
                url={this.props.pageContext.url}
                img={
                  this.props.data.allMaterialGraficoHeaderImage.edges[0].node.localImage
                    .childImageSharp.fluid
                }
              />
              <H2 data={this.PrepareFreya(this.props.data.allMaterialGraficoFreyaBlock)} />
              <Freya data={this.PrepareFreya(this.props.data.allMaterialGraficoFreyaBlock)} />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default MaterialGrafico;
